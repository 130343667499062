<template>
  
  <router-view />
</template>

<script lang="ts" setup>
import { watchEffect, onMounted,ref } from 'vue';  
import { useRoute } from 'vue-router';  
  
const route = useRoute();  
watchEffect(() => {  
  // 检查当前路由的 meta 字段中是否有 title  
  if (route.meta && (route.meta.title as string)) {  
    document.title = route.meta.title as string;  
  }  
  document.documentElement.classList.add('dark');
 
}); 


// 设置其他暗黑模式下的 CSS 变量
</script>

<style lang="scss">
#app {

}
body{
  margin: 0;
  min-width: 1200px;
  // width: 100%;
}
.st_flex{
  display:flex;
  align-items:center;
}
.btn{
  cursor: pointer;
}
.empty_box{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ccc;
  margin-top: 40px;
}

</style>
